import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import objectSupport from 'dayjs/plugin/objectSupport';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { enableMapSet } from 'immer';
import { API_BASE_URL } from './api/api-sdk';
import { routes } from './app.routes';
import { DashboardEffects } from './components/admin/dashboard/state/dashboard.effects';
import { dashboardFeature } from './components/admin/dashboard/state/dashboard.feature';
import { NodesEffects } from './components/admin/nodes/state/nodes.effects';
import { nodesFeature } from './components/admin/nodes/state/nodes.feature';
import { AuthInterceptor } from './http-interceptors/auth-interceptor';
import { LoadingInterceptor } from './http-interceptors/loading-interceptor';
import { AdminEffects } from './state/admin/effects';
import { AuthEffects } from './state/auth/effects';

enableMapSet();

dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);

import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/nl';

import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import autocolors from 'chartjs-plugin-autocolors';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(annotationPlugin);
Chart.register(autocolors);
Chart.register(zoomPlugin);

import { register } from 'swiper/element/bundle';
import { adminFeature } from './state/admin/feature';
import { authFeature } from './state/auth/feature';

register();

export class SigrowDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore({}),
    provideState(authFeature),
    provideState(adminFeature),
    provideState(dashboardFeature),
    provideState(nodesFeature),
    provideEffects([AuthEffects, AdminEffects, DashboardEffects, NodesEffects]),
    provideStoreDevtools({ maxAge: 50, logOnly: !isDevMode() }),
    {
      provide: API_BASE_URL,
      useValue: 'https://api3.sigrow.com',
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    provideNativeDateAdapter(),
    { provide: DateAdapter, useClass: SigrowDateAdapter },
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      }),
    ),
  ],
};
